import React from 'react'
import { URL, TEAM_KEY, YEAR, BLUE_ALLIANCE_API_HEADERS } from "../constants";
import { useEffect, useState } from 'react'
import { Audio } from 'react-loader-spinner'

function Home() {
    
    const [events, setEvents] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getEvents();
    }, [])

    const getEvents = () => {
        fetch(`${URL}/team/${TEAM_KEY}/events/${YEAR}`, BLUE_ALLIANCE_API_HEADERS)
            .then(response => response.json())
            .then(result => {
                setEvents(result);
                setIsLoaded(true);
        })
    }



    return (
        <>
        <h1>Woodstock</h1>

        <p> Found <b>{events.length}</b> competitions for <b>{6897}</b> in <b>{YEAR}</b>:</p>
        {
                        events.map(data => (
                            <div>
                                <div className="card">
                                    <p>
                                        {data.name + ' (' + data.event_type_string + ") "}
                                    </p>
                                </div>
                            </div>
                        ))
                    }

        </>

    ) 
}

export default Home